import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button, styled } from '@mui/material';
import logo from '../../Assets/app_logo.png';
import AuthContainer from '../../components/widget/AuthContainer';

// Styled Button Components
const StyledFilledButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#9E5F08', // Primary color
    color: '#fff',
    fontWeight: 'bold',
    borderRadius: '8px',
    textTransform: 'none',
    '&:hover': {
        backgroundColor: '#8C4F01', // Darker shade on hover
    },
}));

const StyledOutlinedButton = styled(Button)(({ theme }) => ({
    borderColor: '#9E5F08', // Border color
    color: '#9E5F08',
    fontWeight: 'bold',
    borderRadius: '8px',
    textTransform: 'none',
    '&:hover': {
        borderColor: '#8C4F01', // Darker shade on hover
        color: '#8C4F01',
    },
}));

const AuthPage = () => {
    const navigate = useNavigate();

    return (
        <AuthContainer>
            <Box
                sx={{
                    margin: 'auto',
                    textAlign: 'center',
                    boxSizing: 'border-box',
                    px: 2, // Added padding for better spacing
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mb: 2,
                    }}
                >
                    <img src={logo} alt="Logo" width="48px" height="48px" style={{ marginRight: '0.5rem' }} />
                    <Typography
                        variant="h4"
                        sx={{
                            color: '#9E5F08',
                            fontWeight: '700',
                            fontSize: '2rem',
                        }}
                    >
                        Godcast
                    </Typography>
                </Box>
                <Typography
                    variant="h6"
                    sx={{
                        color: '#9E5F08',
                        fontWeight: '700',
                        fontSize: '1rem',
                        mb: 2,
                    }}
                >
                    "Empower Your Voice. Inspire the World."
                </Typography>
                <Typography
                    sx={{
                        color: '#666',
                        fontSize: '0.8rem',
                        maxWidth: '80%',
                        margin: 'auto',
                        mb: 4,
                    }}
                >
                    Share your spiritual message and music with the world. Connect,
                    create, and inspire with Godcast.
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2,
                        mb: 4,
                    }}
                >
                    <StyledFilledButton
                        onClick={() => navigate('/auth/signup')}
                        fullWidth
                    >
                        Sign Up
                    </StyledFilledButton>
                    <StyledOutlinedButton
                        onClick={() => navigate('/auth/signin')}
                        fullWidth
                    >
                        Login
                    </StyledOutlinedButton>
                </Box>
            </Box>
        </AuthContainer>
    );
};

export default AuthPage;
