import React, { useState } from 'react';
import { authenticate, registerUser } from '../../helper/helper';
import toast, { Toaster } from 'react-hot-toast';
import AuthContainer from '../../components/widget/AuthContainer';
import logo from '../../Assets/app_logo.png';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {
    Button,
    TextField,
    Box,
    Typography,
    FormControl,
    FormControlLabel,
    Checkbox,
    RadioGroup,
    Radio,
    Divider,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import VerifyEmailOtp from './VerifyEmailOtp';

// MUI Styled Components for Custom Styling
const StyledTextField = styled(TextField)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    width: '100%',
}));

const SignUp = () => {
    const isDevelopment = process.env.NODE_ENV === 'development';

    const [formData, setFormData] = useState({
        f_name: '',
        l_name: '',
        date_of_birth: '',
        mobile: '',
        dail_code: '',
        email: '',
        password: '',
        confirmPassword: '',
        house_no: '',
        block_name: '',
        pincode: '',
        country: '',
        city: '',
        state: '',
        gender: '',
        band: '',
        infoSharing: false
    });

    const [isBasicDetails, setIsBasicDetails] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [otpSent, setOtpSent] = useState(false);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handlePhoneChange = (value) => {
        setFormData({ ...formData, mobile: value });
    };

    const validatePassword = (password) => {
        const minLength = 8;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumber = /[0-9]/.test(password);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

        if (password.length < minLength) return 'Password must be at least 8 characters long.';
        if (!hasUpperCase) return 'Password must contain at least one uppercase letter.';
        if (!hasLowerCase) return 'Password must contain at least one lowercase letter.';
        if (!hasNumber) return 'Password must contain at least one number.';
        if (!hasSpecialChar) return 'Password must contain at least one special character.';
        return '';
    };

    const validatePincode = (pincode) => {
        const pincodePattern = /^\d{6}$/;
        return !pincodePattern.test(pincode) ? 'Pincode must be exactly 6 digits.' : '';
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const passwordError = validatePassword(formData.password);
        if (passwordError) {
            toast.error(passwordError);
            return;
        }

        if (formData.password !== formData.confirmPassword) {
            toast.error('Passwords do not match');
            return;
        }

        if (isBasicDetails) {
            setIsBasicDetails(false);
            return;
        }

        const pincodeError = validatePincode(formData.pincode);
        if (pincodeError) {
            toast.error(pincodeError);
            return;
        }

        if (!formData.infoSharing) {
            toast.error('Please accept our terms and try again.');
            return;
        }

        if (!validateAge(formData.date_of_birth)) {
            return;
        }

        await sendOtp();
    };

    const sendOtp = async () => {
        const {
            f_name,
            l_name,
            email,
            mobile,
            dail_code,
            date_of_birth,
            password,
            house_no,
            block_name,
            pincode,
            country,
            city,
            state,
            gender,
            band
        } = formData;

        const credentials = {
            name: `${f_name} ${l_name}`,
            f_name,
            l_name,
            email,
            mobile: `${dail_code}${mobile}`,
            dail_code,
            date_of_birth,
            password,
            house_no,
            block_name,
            pincode,
            country,
            city,
            state,
            address: `${house_no}, ${block_name}`,
            colony: '',
            landmark: '',
            provider: 'email',
            avatar: null,
            gender,
            fcm_token: null,
            band,
            country_code: country,
        };

        setLoading(true);
        try {
            const authResponse = await authenticate(email);
            if (authResponse.data.exists) {
                toast.error('Email already exists. Please try a different email or login instead');
            } else {
                await registerUser(credentials);
                toast.success('OTP sent to your email');
                setOtpSent(true);
            }
        } catch (error) {
            setErrorMessage(error.error || 'Registration failed');
            toast.error(error.error || 'Registration failed');
        }
        setLoading(false);
    };

    const validateAge = (dateOfBirth) => {
        const today = new Date();
        const birthDate = new Date(dateOfBirth);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();

        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        if (age < 18) {
            toast.error('You must be at least 18 years old.');
            return false;
        }
        return true;
    };

    return (
        <AuthContainer>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 2,
                    gap: 2,
                    overflowY: 'auto',
                    maxHeight: '100vh',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: 2,
                    }}
                >
                    <img src={logo} alt="Logo" width="57px" height="57px" />
                    <Typography
                        variant="h4"
                        sx={{
                            color: '#9E5F08',
                            fontWeight: '700',
                            ml: 1,
                        }}
                    >
                        Godcast
                    </Typography>
                </Box>
                <Toaster />
                {otpSent ? (
                    <VerifyEmailOtp 
                        email={formData.email} 
                        loading={loading} 
                        onChangeEmail={() => setOtpSent(false)} 
                        onResend={sendOtp}
                    />
                ) : (
                    <Box sx={{ width: '100%' }}>
                        <Typography 
                            variant="h6" 
                            sx={{ mb: 2, fontWeight: 'bold' }}
                        >
                            {isBasicDetails ? 'Register To Continue' : 'Address Details'}
                        </Typography>
                        <form onSubmit={handleSubmit}>
                            <Box>
                                {isBasicDetails ? (
                                    <>
                                        <StyledTextField
                                            name="f_name"
                                            label="First Name"
                                            value={formData.f_name}
                                            onChange={handleChange}
                                            required
                                            autoComplete="given-name"
                                        />
                                        <StyledTextField
                                            name="l_name"
                                            label="Last Name"
                                            value={formData.l_name}
                                            onChange={handleChange}
                                            required
                                            autoComplete="family-name"
                                        />
                                        <StyledTextField
                                            name="date_of_birth"
                                            label="Date of Birth"
                                            type="date"
                                            InputLabelProps={{ shrink: true }}
                                            value={formData.date_of_birth}
                                            onChange={handleChange}
                                            required
                                            autoComplete="bday"
                                        />
                                        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                                            <PhoneInput
                                                country={'IN'}
                                                value={formData.mobile}
                                                onChange={handlePhoneChange}
                                                inputStyle={{ width: '100%' }}
                                                containerStyle={{ flexGrow: '1' }}
                                                required
                                                inputProps={{
                                                    name: 'mobile',
                                                    autoComplete: 'tel',
                                                }}
                                            />
                                        </Box>
                                        <StyledTextField
                                            name="email"
                                            label="Email"
                                            type="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            required
                                            autoComplete="email"
                                        />
                                        <StyledTextField
                                            name="password"
                                            label="Password"
                                            type="password"
                                            value={formData.password}
                                            onChange={handleChange}
                                            required
                                            autoComplete="new-password"
                                        />
                                        <StyledTextField
                                            name="confirmPassword"
                                            label="Confirm Password"
                                            type="password"
                                            value={formData.confirmPassword}
                                            onChange={handleChange}
                                            required
                                            autoComplete="new-password"
                                        />
                                    </>
                                ) : (
                                    <>
                                        <StyledTextField
                                            name="house_no"
                                            label="House No"
                                            value={formData.house_no}
                                            onChange={handleChange}
                                            required
                                            autoComplete="address-line1"
                                        />
                                        <StyledTextField
                                            name="block_name"
                                            label="Block Name"
                                            value={formData.block_name}
                                            onChange={handleChange}
                                            required
                                            autoComplete="address-line2"
                                        />
                                        <StyledTextField
                                            name="pincode"
                                            label="Pincode"
                                            value={formData.pincode}
                                            onChange={handleChange}
                                            required
                                            inputProps={{ maxLength: 6 }}
                                            autoComplete="postal-code"
                                        />
                                        <StyledTextField
                                            name="country"
                                            label="Country"
                                            value={formData.country}
                                            onChange={handleChange}
                                            required
                                            autoComplete="country"
                                        />
                                        <StyledTextField
                                            name="city"
                                            label="City"
                                            value={formData.city}
                                            onChange={handleChange}
                                            required
                                            autoComplete="address-level2"
                                        />
                                        <StyledTextField
                                            name="state"
                                            label="State"
                                            value={formData.state}
                                            onChange={handleChange}
                                            required
                                            autoComplete="address-level1"
                                        />
                                        <StyledTextField
                                            name="band"
                                            label="Band"
                                            value={formData.band}
                                            onChange={handleChange}
                                            required
                                            autoComplete="organization"
                                        />
                                        <FormControl component="fieldset" sx={{ mb: 2 }}>
                                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Select Gender</Typography>
                                            <RadioGroup
                                                name="gender"
                                                value={formData.gender}
                                                onChange={handleChange}
                                                row
                                            >
                                                <FormControlLabel value="male" control={<Radio />} label="Male" />
                                                <FormControlLabel value="female" control={<Radio />} label="Female" />
                                            </RadioGroup>
                                        </FormControl>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="infoSharing"
                                                    checked={formData.infoSharing}
                                                    onChange={handleChange}
                                                />
                                            }
                                            label="By signing up, you agree to Terms of Use and Privacy Policy."
                                        />
                                    </>
                                )}
                                <Button 
                                    type="submit" 
                                    variant="contained" 
                                    color="primary"
                                    sx={{ mt: 2 }}
                                    disabled={loading}
                                >
                                    {isBasicDetails ? "Continue" : "Sign Up"}
                                </Button>
                                <Divider sx={{ my: 2 }} />
                                {!isBasicDetails && (
                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button 
                                            variant="text" 
                                            onClick={() => setIsBasicDetails(!isBasicDetails)}
                                        >
                                            {isBasicDetails ? "Address Details" : "Basic Details"}
                                        </Button>
                                    </Box>
                                )}
                                {errorMessage && <Typography color="error">{errorMessage}</Typography>}
                            </Box>
                        </form>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                            <Typography variant="body2" sx={{ mr: 1 }}>Already have an account?</Typography>
                            <Button variant="text" href="/auth/signin">Log In</Button>
                        </Box>
                    </Box>
                )}
            </Box>
        </AuthContainer>
    );
};

export default SignUp;
