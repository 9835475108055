import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../store/store';
import { verifyPassword } from '../../helper/helper';
import toast, { Toaster } from 'react-hot-toast';
import AuthContainer from '../../components/widget/AuthContainer';
import logo from '../../Assets/app_logo.png';
import { Button, TextField, Typography, Box } from '@mui/material';
import { createSession } from '../../middleware/auth';

const SignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const setEmailInStore = useAuthStore((state) => state.setEmail);
    const navigate = useNavigate();

    const handleSignIn = async (e) => {
        e.preventDefault();
        setLoading(true);
        setErrorMessage('');
        try {
            const response = await verifyPassword({ email, password });
            if (response) {
                setEmailInStore(email);
                createSession(response.data.token,JSON.stringify(response.data.user));

                setErrorMessage('');
                toast.success('Sign-in successful');
                navigate('/'); // Navigate to home page
            }
        } catch (error) {
            setErrorMessage(error.error || 'Password does not match.');
            toast.error(error.error || 'Password does not match.');
        }
        setLoading(false);
    };

    return (
        <AuthContainer>
            <Box
                sx={{
                    maxWidth: '500px',
                    margin: 'auto',
                    textAlign: 'center',
                    padding: '1rem',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mb: 2,
                    }}
                >
                    <img src={logo} alt="Logo" width="57px" height="57px" style={{ marginRight: '0.5rem' }} />
                    <Typography
                        variant="h4"
                        sx={{
                            color: '#B57A25',
                            fontWeight: '700',
                            fontSize: '2rem',
                        }}
                    >
                        Godcast
                    </Typography>
                </Box>
                <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
                    Login To Continue
                </Typography>
                <Toaster />
                <form onSubmit={handleSignIn}>
                    <TextField
                        label="Email"
                        variant="outlined"
                        type="email"
                        value={email}
                        autoComplete='email'
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        fullWidth
                        sx={{
                            mb: 2,
                            '& .MuiInputLabel-root': {
                                color: 'text.primary',
                            },
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '8px',
                                '& fieldset': {
                                    borderColor: 'grey.300',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'primary.main',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'primary.main',
                                },
                            },
                        }}
                    />
                    <TextField
                        label="Password"
                        variant="outlined"
                        type="password"
                        autoComplete='password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        fullWidth
                        sx={{
                            mb: 2,
                            '& .MuiInputLabel-root': {
                                color: 'text.primary',
                            },
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '8px',
                                '& fieldset': {
                                    borderColor: 'grey.300',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'primary.main',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'primary.main',
                                },
                            },
                        }}
                    />
                    <Box sx={{ mb: 2 }}>
                        <Button
                            variant="text"
                            onClick={() => navigate('/auth/forgot-password')}
                            sx={{ color: '#B57A25' }}
                        >
                            Forgot Password?
                        </Button>
                    </Box>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        disabled={loading}
                    >
                        {loading ? 'Signing In...' : 'Sign In'}
                    </Button>
                    {errorMessage && (
                        <Typography color="error" sx={{ mt: 2 }}>
                            {errorMessage}
                        </Typography>
                    )}
                </form>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 2,
                    }}
                >
                    <Typography variant="body2" sx={{ mr: 1 }}>
                        Don’t have an account?
                    </Typography>
                    <Button
                        variant="text"
                        onClick={() => navigate('/auth/signup')}
                        sx={{ color: '#B57A25' }}
                    >
                        Create New User
                    </Button>
                </Box>
            </Box>
        </AuthContainer>
    );
};

export default SignIn;
