import React, { useState, useRef, useEffect } from 'react';
import { Grid, TextField, Paper } from '@mui/material';
import PropTypes from 'prop-types';

const OtpInput = ({ otp = '', onChange = () => {} }) => {
    const inputRefs = useRef([]);
    const [otpArray, setOtpArray] = useState(
        Array.from({ length: 6 }, (_, i) => otp[i] || '')
    );

    useEffect(() => {
        inputRefs.current[0]?.focus();
    }, []);

    // Handle change event for individual inputs
    const handleChange = (index, e) => {
        const value = e.target.value;

        // Only allow single digits or empty
        if (/^\d$/.test(value) || value === '') {
            const newOtpArray = [...otpArray];
            newOtpArray[index] = value;

            setOtpArray(newOtpArray);
            onChange(newOtpArray.join(''));

            // Move focus to the next input field
            if (value && index < otpArray.length - 1) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    // Handle key down events for better navigation
    const handleKeyDown = (index, e) => {
        if (e.key === 'Backspace' && !otpArray[index] && index > 0) {
            inputRefs.current[index - 1].focus();
        } else if (e.key === 'ArrowLeft' && index > 0) {
            inputRefs.current[index - 1].focus();
        } else if (e.key === 'ArrowRight' && index < otpArray.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    // Handle paste event
    const handlePaste = (e) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData('text').replace(/\D/g, '').slice(0, 6);
        const newOtpArray = Array.from({ length: 6 }, (_, i) => pastedData[i] || '');

        setOtpArray(newOtpArray);
        onChange(newOtpArray.join(''));

        // Focus the last input field that was updated
        const lastFilledIndex = pastedData.length > 0 ? Math.min(pastedData.length - 1, 5) : 0;
        inputRefs.current[lastFilledIndex]?.focus();
    };

    return (
        <Grid container spacing={2} justifyContent="center">
            {otpArray.map((value, index) => (
                <Grid item key={`otp-input-${index}`}>
                    <Paper
                        elevation={3}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '48px',
                            height: '48px',
                        }}
                    >
                        <TextField
                            inputRef={(el) => (inputRefs.current[index] = el)}
                            value={value}
                            onChange={(e) => handleChange(index, e)}
                            onKeyDown={(e) => handleKeyDown(index, e)}
                            onPaste={handlePaste}
                            variant="outlined"
                            inputProps={{
                                maxLength: 1,
                            }}
                            InputProps={{
                                style: {
                                    textAlign: 'center',
                                    width: '48px',
                                    height: '48px',
                                    padding: '0',
                                    fontSize: '1.5rem',
                                    border: 'none',
                                    boxShadow: 'none',
                                },
                                inputMode: 'numeric',
                                pattern: '[0-9]*',
                                'aria-label': `OTP Digit ${index + 1}`,
                            }}
                            sx={{
                                '& input': {
                                    textAlign: 'center',
                                    width: '48px',
                                    height: '48px',
                                    padding: '0',
                                    fontSize: '1.5rem',
                                    border: 'none',
                                    boxShadow: 'none',
                                },
                            }}
                        />
                    </Paper>
                </Grid>
            ))}
        </Grid>
    );
};

OtpInput.propTypes = {
    otp: PropTypes.string,
    onChange: PropTypes.func,
};

export default OtpInput;
