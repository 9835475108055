import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authenticate, forgotPasswordSentOtp, resetPassword } from '../../helper/helper';
import { useAuthStore } from '../../store/store';
import toast, { Toaster } from 'react-hot-toast';
import { Container, Typography, TextField, Button, Box, CircularProgress } from '@mui/material';
import AuthContainer from '../../components/widget/AuthContainer';
import logo from '../../Assets/app_logo.png';
import BackButton from '../../components/buttons/BackButton';
import OtpInput from '../../components/widget/OtpInput'; // Import the OtpInput component
import { createSession } from '../../middleware/auth';

const ForgotPassword = () => {
    const isDevelopment = process.env.NODE_ENV === 'development';
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [durationText, setDurationText] = useState('');
    const [duration, setDuration] = useState(180);
    const [loading, setLoading] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [timerZero, setTimerZero] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const setEmailInStore = useAuthStore((state) => state.setEmail);
    const navigate = useNavigate();

    const startTimer = () => {
        // Clear any existing timer
        if (window.timerInterval) {
            clearInterval(window.timerInterval);
        }
    
        let timer = duration;
        window.timerInterval = setInterval(() => {
            const minutes = Math.floor(timer / 60).toString().padStart(2, '0');
            const seconds = (timer % 60).toString().padStart(2, '0');
            setDurationText(`${minutes}:${seconds}`);
            if (--timer < 0) {
                clearInterval(window.timerInterval);
                setDurationText('');
                setTimerZero(true);
            }
        }, 1000);
    };

    const validatePassword = (password) => {
        const minLength = 8;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumber = /[0-9]/.test(password);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    
        if (password.length < minLength) {
            return 'Password must be at least 8 characters long.';
        }
        if (!hasUpperCase) {
            return 'Password must contain at least one uppercase letter.';
        }
        if (!hasLowerCase) {
            return 'Password must contain at least one lowercase letter.';
        }
        if (!hasNumber) {
            return 'Password must contain at least one number.';
        }
        if (!hasSpecialChar) {
            return 'Password must contain at least one special character.';
        }
        return '';
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();
        setErrorMessage('');

        if (!otpSent) {
            return sendOtp(e);
        }

        const passwordError = validatePassword(newPassword);
        if (passwordError) {
            toast.error(passwordError);
            return;
        }

        if (newPassword !== confirmPassword) {
            setErrorMessage('Passwords do not match.');
            toast.error('Passwords do not match.');
            return;
        }

        setLoading(true);
        try {
            const response = await resetPassword({ email, password: newPassword, otp });
            setEmailInStore(email);
            console.log(response);

            createSession(response.data.token,JSON.stringify(response.data.user));
            setErrorMessage('');
            toast.success('Password reset successful');
            navigate('/'); // Navigate to home page
        } catch (error) {
            setErrorMessage(error.error);
            toast.error(error.error);
        }
        setLoading(false);
    };

    const handleEmailChange = () => {
        // Reset all related states when changing email
        setOtpSent(false);
        setEmail('');
        setOtp('');
        setNewPassword('');
        setConfirmPassword('');
        setErrorMessage('');
        setDurationText('');
        setDuration(180); // Reset duration to initial value
    };

    const sendOtp = async (e) => {
        e.preventDefault();
        if (email === '') {
            setErrorMessage('Please enter a valid email address');
            toast.error('Please enter a valid email address.');
            return;
        }
        setLoading(true);
        setOtp('');
        setNewPassword('');
        setErrorMessage('');
        setConfirmPassword('');
        try {
            // Check if the email exists
            const authResponse = await authenticate(email);
            if (!authResponse.data.exists) {
                setErrorMessage('Email does not exist.');
                toast.error('Email does not exist. Please register to continue.');
                setLoading(false);
                return;
            }

            const response = await forgotPasswordSentOtp({ email });
            if (response) {
                setOtpSent(true);
                setTimerZero(false);
                startTimer();
            }
        } catch (error) {
            setErrorMessage(error || 'Failed to reset password.');
            toast.error(error || 'An unexpected error occurred.');
        }
        setLoading(false);
    };

    const handleResendOtp = async () => {
        setLoading(true);
        setDuration(180); // Reset duration
        setOtpSent(true); // Ensure OTP sent state is true to show timer and continue button
        setTimerZero(false);
        try {
            const response = await forgotPasswordSentOtp({ email });
            if (response) {
                startTimer();
            }
        } catch (error) {
            setErrorMessage(error || 'Failed to resend OTP.');
            toast.error(error || 'An unexpected error occurred.');
        }
        setLoading(false);
    };

    return (
        <AuthContainer>
            <Container
                maxWidth="sm"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '1rem',
                    textAlign: 'center',
                }}
            >
                <Box sx={{ alignSelf: 'flex-start', mb: 2 }}>
                    <BackButton />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: 2
                    }}
                >
                    <img src={logo} alt="App Logo" width="57px" height="57px" />
                    <Typography variant="h4" component="h1" sx={{ ml: 1, fontWeight: 700, color: '#B57A25' }}>
                        Godcast
                    </Typography>
                </Box>
                <Typography variant="h5" component="h2" gutterBottom>
                    Forgot Password
                </Typography>
                <Toaster />
                <form onSubmit={handleResetPassword} style={{ width: '100%' }}>
                    {!otpSent && (
                        <Box sx={{ mb: 2 }}>
                            <TextField
                                fullWidth
                                label="Email"
                                type="email"
                                value={email}
                                autoComplete="email"
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                variant="outlined"
                                sx={{ mb: 2 }}
                            />
                        </Box>
                    )}
                    {otpSent && (
                        <>
                            <Box sx={{ mb: 2 }}>
                                <Typography variant="body1" gutterBottom>
                                    OTP:
                                </Typography>
                                <OtpInput otp={otp.split('')} onChange={(value) => setOtp(value)} />
                                <Box sx={{ textAlign: 'end', display: 'flex', alignItems: 'center', mt: 1 }}>
                                    <Button
                                        variant="text"
                                        onClick={handleResendOtp}
                                        disabled={loading}
                                    >
                                        Resend OTP
                                    </Button>
                                    {!loading && durationText && (
                                        <Typography variant="body2" sx={{ ml: 1 }}>
                                            {durationText}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                            <Box sx={{ mb: 2 }}>
                                <TextField
                                    fullWidth
                                    label="New Password"
                                    type="password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    required
                                    variant="outlined"
                                    sx={{ mb: 2 }}
                                />
                            </Box>
                            <Box sx={{ mb: 2 }}>
                                <TextField
                                    fullWidth
                                    label="Confirm New Password"
                                    type="password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                    variant="outlined"
                                    sx={{ mb: 2 }}
                                />
                            </Box>
                        </>
                    )}
                    {errorMessage && (
                        <Typography color="error" variant="body2" sx={{ mb: 2 }}>
                            {errorMessage}
                        </Typography>
                    )}
                    {timerZero && otpSent ? (
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={sendOtp}
                            disabled={loading}
                            sx={{ width: '100%' }}
                        >
                            {loading ? <CircularProgress size={24} /> : 'Resend OTP'}
                        </Button>
                    ) : (
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={loading}
                            sx={{ width: '100%' }}
                        >
                            {loading ? <CircularProgress size={24} /> : otpSent ? 'Continue' : 'Send OTP'}
                        </Button>
                    )}
                </form>
            </Container>
        </AuthContainer>
    );
};

export default ForgotPassword;
