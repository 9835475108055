import { Navigate, useNavigate } from "react-router-dom";
import { useAuthStore } from "../store/store";
import PropTypes from 'prop-types';
import axios from "axios";
import { apiSecureHeader } from "../helper/constants";
import { getUser } from "../helper/userHelper";

export const AuthorizeUser = ({ children }) => {

    if (!getToken()) {
        return <Navigate to={'/auth'} replace={true} />;
    }

    return children;
};

AuthorizeUser.propTypes = {
    children: PropTypes.node.isRequired
};

export const RedirectIfAuthenticated = ({ children }) => {
    if (getToken()) {
        return <Navigate to={'/'} replace={true} />;
    }
    return children;
};

RedirectIfAuthenticated.propTypes = {
    children: PropTypes.node.isRequired
};

export const createSession = (token, user) => {
    localStorage.setItem('token', token); // Store the token
    localStorage.setItem('user', user);
    setExpiry();
};

export const setExpiry = () => {
    const now = new Date();
    const ttl = 24 * 60 * 60 * 1000; // Calculate TTL in milliseconds
    localStorage.setItem('expiry', JSON.stringify(now.getTime() + ttl));
    localStorage.setItem('last_checked_at', JSON.stringify(now.getTime()));
};

export const getToken = () => {
    const token = localStorage.getItem('token');
    const itemStr = localStorage.getItem('expiry');

    // If the item doesn't exist, return null
    if (!itemStr || !token) {
        return null;
    }

    // Parse the item back into an object
    const item = JSON.parse(itemStr);
    const now = new Date();

    // Compare the expiry time with the current time
    if (now.getTime() > item) {
        // If the item has expired, remove it from localStorage and return null
        expireSession();
        return null;
    }

    return validateToken();
};
export const validateToken = () => {
    const lastCheckedAt = localStorage.getItem('last_checked_at');

    // If the item doesn't exist, return null
    if (lastCheckedAt) {
        const lDate = JSON.parse(lastCheckedAt);



        const date = new Date();
        const difference = date.getTime() - lDate;

        if ((difference / 1000) > 120) {
            validateUser();
        }

    }


    return localStorage.getItem('token');

};

export const validateUser = async () => {
    console.log('validateUser', 'validateUser');
    try {
        const userData = await getUser(); // Fetch from API if not in localStorage
        localStorage.setItem('user', JSON.stringify(userData));
        const now = new Date();
        localStorage.setItem('last_checked_at', JSON.stringify(now.getTime()));





    } catch (error) {
        expireSession();
        alert("Session Expired please login again");
    }

}

export const expireSession = () => {
    localStorage.removeItem('expiry');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
}